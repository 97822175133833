<template>
    <v-row>
        <v-col cols="12" md="6" lg="3">
            <CardEstadistica :is-loading="estadisticas.isLoading">
                <template v-slot:card-title>
                    <div class="d-flex align-center flex-wrap" style="gap: 8px">
                        <v-icon>mdi-file-document</v-icon>
                        <h6 class="text-subtitle-2 font-weight-bold text-uppercase">Total de instituciones</h6>
                    </div>
                </template>
                <template v-slot:card-text>
                    <span class="text-h4 font-weight-bold secondary--text d-block text-center">
                        {{ estadisticas.data?.instituciones ?? 0 }}
                    </span>
                </template>
            </CardEstadistica>
        </v-col>
        <v-col cols="12" md="6" lg="3">
            <CardEstadistica :is-loading="estadisticas.isLoading">
                <template v-slot:card-title>
                    <div class="d-flex align-center flex-wrap" style="gap: 8px">
                        <v-icon>mdi-file-document-alert</v-icon>
                        <h6 class="text-subtitle-2 font-weight-bold text-uppercase">Instituciones pendientes</h6>
                    </div>
                </template>
                <template v-slot:card-text>
                    <span class="text-h4 font-weight-bold secondary--text d-block text-center">
                        {{ estadisticas.data?.instituciones_pendientes ?? 0 }}
                    </span>
                </template>
            </CardEstadistica>
        </v-col>

        <v-col cols="12" md="6" lg="3">
            <CardEstadistica :is-loading="estadisticas.isLoading">
                <template v-slot:card-title>
                    <div class="d-flex align-center flex-wrap" style="gap: 8px">
                        <v-icon>mdi-file-upload</v-icon>
                        <h6 class="text-subtitle-2 font-weight-bold text-uppercase">Documentos cargados</h6>
                    </div>
                </template>
                <template v-slot:card-text>
                    <div class="d-flex align-center justify-center" style="gap: 16px;">
                        <span class="text-h4 font-weight-bold secondary--text">{{ estadisticas.data?.instituciones_documentos_cargados ?? 0 }}</span>
                        <v-divider vertical />
                        <span class="text-subtitle-1 font-weight-bold">
                            {{ mostrarPorcentaje(estadisticas.data?.porcentaje_instituciones_documento_cargado ?? 0) }}
                        </span>
                    </div>
                </template>
            </CardEstadistica>
        </v-col>

        <v-col cols="12" md="6" lg="3">
            <CardEstadistica :is-loading="estadisticas.isLoading">
                <template v-slot:card-title>
                    <div class="d-flex align-center" style="gap: 8px;">
                        <v-icon>mdi-file-document-check</v-icon>
                        <span class="text-subtitle-2 font-weight-bold text-uppercase" style="word-break: break-word;">
                            Documentos aprobados
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        v-on="on" 
                                        v-bind="attrs" 
                                        size="20" 
                                        color="secondary"
                                        >
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span>Este porcentaje es en base a la cantidad de documentos cargados.</span>
                            </v-tooltip>
                        </span>
                    </div>
                </template>
                <template v-slot:card-text>
                    <div class="d-flex justify-center align-center" style="gap: 16px;">
                        <span class="text-h4 font-weight-bold secondary--text">{{ estadisticas.data?.instituciones_documentos_aprobados ?? 0 }}</span>
                        <v-divider vertical />
                        <span class="text-subtitle-1 font-weight-bold">
                            {{ mostrarPorcentaje(estadisticas.data?.porcentaje_instituciones_documento_aprobado ?? 0) }}
                        </span>
                    </div>
                </template>
            </CardEstadistica>
        </v-col>
    </v-row>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import CardEstadistica from '../compartido/CardEstadistica.vue';
import { mostrarPorcentaje } from '@/utils/data';

export default {
    name: 'EstadisticasAutorizacionPAC',
    components: { CardEstadistica },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        estadisticas: createLoadable(null),
    }),
    methods: {
        // UI
        mostrarPorcentaje,
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticas(payload);
        },
        // DATA
        async cargarEstadisticas(filtros) {
            toggleLoadable(this.estadisticas);
            const { data } = await this.services.ReporteriaAutorizacionPAC.cargarEstadisticas(filtros);
            setLoadableResponse(this.estadisticas, data);
        },
    },
    created() {
        // this.cargarEstadisticas();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>   