<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="gap: 16px;">
          <NavButton />
          <h2 class="text-h6 text-uppercase font-weight-bold" style="word-break: break-word;">
            Autorización procesos PAC
          </h2>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-4 py-4">
        <FiltrosSticky icono="mdi-filter-settings">
          <v-row>
            <v-col cols="12" md="4">
              <InstitucionesAutoComplete
                :value.sync="filtros.id_institucion"
                :evento-reinicio="eventoRestablecerFiltros"
                :listener="institucionesListener"
                :autocomplete-props="{ disabled: false }"
                solo-instituciones-padre
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="filtros.id_anio_fiscal"
                outlined
                hide-details
                :items="aniosFiscales.data"
                :loading="aniosFiscales.isLoading"
                :disabled="false"
                item-value="id"
                item-text="anio"
                label="Año"
                placeholder="Seleccione un año"
              />
            </v-col>

            <v-col cols="12" md="4">
              <div
                class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap"
                style="gap: 16px"
              >
                <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  @click.stop="hacerBusquedaConFiltros"
                  x-large
                >
                  Buscar
                </v-btn>
                <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  @click.stop="restablecerInformacion"
                  outlined
                  x-large
                >
                  Limpiar
                </v-btn>

                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="flex-grow-1 flex-shrink-1"
                      @click.stop="descargarReporteExcel"
                      outlined
                      x-large
                      v-bind="attrs"
                      v-on="on"
                      
                    >
                      <v-icon>mdi mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar a excel</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </FiltrosSticky>

        <Estadisticas
          class="mt-4"
          :evento-busqueda="eventoBusquedaFiltros"
          :listener="estadisticasListener"
        />
        <DataTableComponent
          class="mt-4"
          :items="documentos.data"
          :show_loading="documentos.isLoading"
          :headers="headers"
          :total_registros="registrosTotales"
          @paginar="manejarPaginacion"
          v-models:pagina="paginacion.pagina"
          v-models:select="paginacion.registrosPorPagina"
          dense
          no-gutters
        >
          <template v-slot:item.estado="{ item }">
            <v-chip
              :color="obtenerEstadoDocumento(item).color"
              class="white--text py-2 mx-auto"
              style="max-width: 250px"
              label
            >
              <div class="d-flex align-center" style="gap: 8px">
                <v-icon color="white" size="20px !important">{{
                  obtenerEstadoDocumento(item).icono
                }}</v-icon>
                <span>{{ obtenerEstadoDocumento(item).texto }}</span>
              </div>
            </v-chip>
          </template>
          <template v-slot:item.numero_proceso="{ item }">
            {{ item.documento?.numero_proceso ?? "-" }}
          </template>
          <template v-slot:item.avance="{ item }">
            {{
              item.documento?.numero_proceso > 0
                ? `${item.procesos_pac} (${mostrarPorcentaje(
                    item.porcentaje_avance
                  )})`
                : "-"
            }}
          </template>
          <template v-slot:item.aprobacion="{ item }">
            <div
              v-if="
                item.documento?.created_at ||
                item.documento?.aprobado_en ||
                item.documento?.fecha_aprobacion
              "
              class="py-4"
            >
              <v-tooltip v-if="item.documento?.created_at" top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex align-center justify-center"
                    style="gap: 8px"
                  >
                    <v-icon size="20px !important">mdi-file-upload</v-icon>
                    {{ formatDate(item.documento.created_at) }}
                  </div>
                </template>
                <span>Fecha de carga</span>
              </v-tooltip>

              <v-tooltip v-if="item.documento?.aprobado_en" top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex align-center justify-center mt-4"
                    style="gap: 8px"
                  >
                    <v-icon size="20px !important">mdi-laptop</v-icon>
                    {{ formatDate(item.documento.aprobado_en) }}
                  </div>
                </template>
                <span>Aprobación en sistema</span>
              </v-tooltip>

              <v-tooltip v-if="item.documento?.fecha_aprobacion" top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex align-center justify-center mt-4"
                    style="gap: 8px"
                  >
                    <v-icon size="20px !important">mdi-account-check</v-icon>
                    {{ formatDate(item.documento.fecha_aprobacion) }}
                  </div>
                </template>
                <span>Fecha de aprobación</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex align-center justify-center" style="gap: 16px">
              <v-btn
                :disabled="!item.documento"
                @click.stop="visualizarDocumento(item)"
                icon
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>

              <template v-if="item.documento?.AnioFiscal?.nombre && formatDate(item.documento.AnioFiscal.nombre, 'YYYY') == new Date().getFullYear() && haveRole('ROLE_REVERTIR_DOC_ANIO_PAC')">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!item.documento.aprobado"
                      @click="revertirModal(item.documento?.id)"
                      icon
                      v-bind="attrs"
                      v-on="on"                    
                    >
                      <v-icon>mdi-arrow-u-left-top</v-icon>
                    </v-btn>
                  </template>
                  <span>Revertir Aprobación</span>
                </v-tooltip>
              </template>
            </div>
          </template>
        </DataTableComponent>

        <PdfModal
          :isOpen.sync="previsualizacionAbierta"
          :source-loadable="visualizacionDocumento"
          filename="documento-autorizacion"
        />
      </v-card-text>
    </v-card>
    
    <ConfirmationDialogComponent
      :show="confirmationDialog"
      btnConfirmar="Revertir registro"
      btnCancel="Cancelar"
      :title="`¿Desea revertir el documento seleccionado?`"
      :message="`Esta acción es irreversible`"
      @close="(confirmationDialog = false), (registro_selected_var = null)"
      @confirm="revertirEstadoDoc"
    />
  </v-container>
</template>
<script>
import moment from "moment";

import InstitucionesAutoComplete from "@/components/compartidos/InstitucionesAutoComplete.vue";
import {
  createLoadable,
  createPageable,
  setLoadableResponse,
  setPageableResponse,
  toggleLoadable,
  togglePageable,
} from "@/utils/loadable";
import DataTableComponent from "@/components/DataTableComponent.vue";
import Estadisticas from "./autorizacionPAC/Estadisticas.vue";
import FiltrosSticky from "./components/FiltrosSticky.vue";
import NavButton from "@/components/utils/NavButton.vue";
import { Listener, Observable } from "@/utils/observable";
import PdfModal from "@/components/PdfModal.vue";
import { formatDate } from "@/utils/datetime";
import { mostrarPorcentaje } from "@/utils/data";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

const filtrosIniciales = {
  id_institucion: null,
  id_anio_fiscal: null,
};

export default {
  components: {
    NavButton,
    FiltrosSticky,
    InstitucionesAutoComplete,
    DataTableComponent,
    Estadisticas,
    PdfModal,
    ConfirmationDialogComponent,
  },
  data: () => ({
    // FILTROS
    filtros: { ...filtrosIniciales },
    aniosFiscales: createLoadable([]),

    // UI
    previsualizacionAbierta: false,
    estadisticasListener: new Listener("EstadisticasListener"),
    institucionesListener: new Listener("InstitucionesListener"),
    eventoRestablecerFiltros:
      "RESTABLECER_INFORMACION_REPORTERIA_AUTORIZACION_PAC",
    eventoBusquedaFiltros: "BUSCAR_INFORMACION_REPORTERIA_AUTORIZACION_PAC",
    unsubscribers: [],

    // DATA PRINCIPAL
    paginacion: {
      pagina: 1,
      registrosPorPagina: 10,
    },
    documentos: createPageable([], 10),
    visualizacionDocumento: createLoadable(null),
    revertir: createLoadable([]),
    confirmationDialog: false,
    registro_selected_var: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Institución",
          value: "nombre",
          align: "center",
          sortable: false,
        },
        { text: "Estado", value: "estado", align: "center", sortable: false },
        {
          text: "Número de procesos",
          value: "numero_proceso",
          align: "center",
          sortable: false,
        },
        { text: "% Avance", value: "avance", align: "center", sortable: false },
        {
          text: "Aprobación",
          value: "aprobacion",
          align: "center",
          sortable: false,
        },
        {
          text: "Documento",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ];
    },
    registrosTotales() {
      return this.documentos.pagination.total_rows;
    },
  },
  methods: {
    // UI
    formatDate,
    mostrarPorcentaje,
    obtenerAnioActual(anios) {
      const anioActual = new Date().getFullYear();
      return anios.find((anio) => anio.anio === anioActual);
    },
    restablecerInformacion() {
      this.filtros = { ...filtrosIniciales };
      const anio = this.obtenerAnioActual(this.aniosFiscales.data);
      this.filtros.id_anio_fiscal = anio?.id ?? null;

      Observable.emit(this.eventoRestablecerFiltros);
      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });

      this.paginacion = { pagina: 1, registrosPorPagina: 10 };
      this.cargarDocumentos();
    },
    hacerBusquedaConFiltros() {
      this.paginacion.pagina = 1;
      this.cargarDocumentos();

      Observable.emit(this.eventoBusquedaFiltros, { ...this.filtros });
    },
    obtenerEstadoDocumento(institucion) {
      const estados = {
        pendiente: {
          texto: "Documento pendiente",
          icono: "mdi-file-document-alert",
          color: "info",
        },
        cargado: {
          texto: "Documento cargado",
          icono: "mdi-file-upload",
          color: "primary",
        },
        aprobado: {
          texto: "Documento aprobado",
          icono: "mdi-file-document-check",
          color: "success",
        },
      };

      const documento = institucion.documento;

      if (!documento) return estados.pendiente;

      return documento.aprobado ? estados.aprobado : estados.cargado;
    },
    manejarPaginacion(paginacion) {
      const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
      this.paginacion = { pagina, registrosPorPagina };

      this.cargarDocumentos();
    },

    // DATA
    async cargarAniosFiscales() {
      toggleLoadable(this.aniosFiscales);
      const { data } = await this.services.PacProcesos.cargarAniosFiscales();
      const anios = Array.isArray(data) ? data : [];
      setLoadableResponse(this.aniosFiscales, { data: anios });

      return anios;
    },
    async cargarDocumentos() {
      const filtros = {
        pagination: true,
        page: this.paginacion.pagina,
        per_page: this.paginacion.registrosPorPagina,
        id_institucion: this.filtros.id_institucion,
        id_anio_fiscal: this.filtros.id_anio_fiscal,
      };

      togglePageable(this.documentos);
      const { data, headers } =
        await this.services.ReporteriaAutorizacionPAC.cargarDocumentos(filtros);
      setPageableResponse(this.documentos, data, headers);
    },
    async visualizarDocumento(institucion) {
      this.previsualizacionAbierta = true;
      toggleLoadable(this.visualizacionDocumento);
      const { data } =
        await this.services.ReporteriaAutorizacionPAC.visualizarDocumento(
          institucion.documento.id
        );
      setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
    },
    async descargarReporteExcel() {
      const { data } =
        await this.services.ReporteriaAutorizacionPAC.descargarExcel(
          this.filtros
        );

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const name = `Reporte autorizacion${moment().format("YYYY-MM-DD HH:mm")}.xlsx`;

      const file = new File([blob], name, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = name;
      link.click();
    },
    async revertirEstadoDoc() {
      if (!this.registro_selected_var) return;
      toggleLoadable(this.revertir)
      const { data } = await this.services.PacProcesos.revertirEstadoDoc(this.registro_selected_var);
      this.cargarDocumentos();
      setLoadableResponse(this.revertir, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.confirmationDialog = false;
      this.registro_selected_var = null;
    },
    revertirModal(id) {
      this.confirmationDialog = true;
      this.registro_selected_var = id;
    }
  },
  created() {
    this.cargarAniosFiscales().then((anios) => {
      const anio = this.obtenerAnioActual(anios);
      this.filtros.id_anio_fiscal = anio?.id ?? null;
      this.hacerBusquedaConFiltros();
    });

    this.unsubscribers = [
      Observable.getInstance().subscribe(
        this.eventoRestablecerFiltros,
        this.institucionesListener
      ),
      Observable.getInstance().subscribe(
        this.eventoBusquedaFiltros,
        this.estadisticasListener
      ),
    ];
  },
  beforeDestroy() {
    this.unsubscribers.forEach((unsubscriber) => unsubscriber());
  },
};
</script>
